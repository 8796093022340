import { useContext, createContext, useState, useEffect } from "react";
import AlertDialog from "../components/AlertDialog";
import Typography from "@mui/material/Typography";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  // save data
  const handleSaveEvalData = async (empId, evalType, evalData) => {
    const url = `https://97bt6d9ox6.execute-api.us-east-1.amazonaws.com/v1/dev`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    const body = {
      empId: empId,
      evalDate: new Date(),
      evalType: evalType,
      evalData: evalData,
    };
    console.log("posting", body);

    try {
      return fetch(url, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((jsonResponse) => {
          console.log(jsonResponse);
          const msg = {
            title: "Evaluation Response",
            body: (
              <>
                {jsonResponse.status === "success" ? (
                  <>
                    <Typography variant="subtitle2">Saved:</Typography>
                    <Typography variant="body1">
                      {`${evalType} Evaluation saved for employee: ${empId}`}
                    </Typography>
                    <br />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">Failed:</Typography>
                    <Typography variant="body1">
                      {`${jsonResponse.message}`}
                    </Typography>
                  </>
                )}
              </>
            ),
          };
          return msg;
        });
    } catch (error) {
      console.log("Error: ", error.message);
      openAlert({ title: "Error", body: error.message });
    }
  };

  const handleInit = () => {};

  useEffect(() => {
    handleInit();
  }, []);

  // alert common
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    title: "Alert title",
    body: (
      <>
        <div>Description-1</div>
        <div>Description-2</div>
      </>
    ),
  });

  const openAlert = (msg) => {
    // console.log("alert", msg);
    setAlertMsg(msg);
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const value = {
    handleSaveEvalData,
    openAlert,
  };

  return (
    <DataContext.Provider value={value}>
      <>
        {children}
        <AlertDialog
          open={alertOpen}
          handleClose={handleClose}
          alertMsg={alertMsg}
        />
      </>
    </DataContext.Provider>
  );
};

export default DataProvider;
