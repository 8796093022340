import React from "react";
import Copyright from "../components/Copyright";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Appicon from "../components/Appicon";

const Home = ({ version, apps, handleOpen }) => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 90px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        sx={{
          px: 10,
          py: 10,
          mt: "64px",
          height: "calc(100% - 164px)",
          // border: "1px red dashed",
        }}
      >
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 100,
            }}
          >
            <Typography variant="h4" sx={{ mx: 3 }}>
              Installed Flairz Apps
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {apps.map((app) => {
              return (
                <Appicon
                  key={app.appId}
                  appName={app.appName}
                  appImgPath={app.appImagePath}
                  appId={app.appId}
                  handleOpen={() => handleOpen(app.appId)}
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "auto", marginBottom: "20px" }}>
        <Copyright version={version} />
      </Box>
    </Box>
  );
};

Home.displayName = "Home";
Home.defaultProps = {
  pageTitle: "Flairz",
};
Home.auth = false;
export default Home;
