import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Copyright = (props) => {
  const { version } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Version "}
        {version}
        {" | "}
        <a
          color="inherit"
          href="https://flairzhealth.com/flairz-ctrl-privacy-policy/"
        >
          Privacy Policy
        </a>
        {" | "}
        {"Copyright © "}
        <a color="inherit" href="https://flairzhealth.com/">
          flairzhealth
        </a>{" "}
        {new Date().getFullYear()}
        {". All Rights Reserved."}
      </Typography>
    </Box>
  );
};

export default Copyright;
