import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Appicon = ({ appName, appImgPath, handleOpen }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={handleOpen}
    >
      <Paper
        elevation={6}
        sx={{
          backgroundColor: "#004374",
          borderRadius: "32px",
          height: "142px",
          width: "142px",
          mx: 4,
          boxShadow: 24,
          "&:hover": {
            border: "3px aliceblue solid",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <img src={appImgPath} alt="msk" width="80%" height="80%" />
        </Box>
      </Paper>
      <Typography
        variant="subtitle2"
        sx={{
          mt: 1,
          fontWeight: 500,
          "&:hover": {
            fontWeight: 700,
          },
        }}
      >
        {appName}
      </Typography>
    </Box>
  );
};

export default Appicon;
