import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const Chartbar = ({ data }) => {
  const customTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ fontSize: "16px" }}
          x={0}
          y={0}
          dy={10}
          dx={-6}
          textAnchor="end"
          fill="#666"
          transform="rotate(-55)"
        >
          {payload.value}
        </text>
      </g>
    );
  };
  const customTickY = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ fontSize: "13px" }}
          x={0}
          y={0}
          dy={10}
          dx={-6}
          textAnchor="end"
          fill="#666"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      <BarChart
        width={500}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={50}
      >
        <XAxis dataKey="name" interval={0} height={100} tick={customTick} />
        <YAxis type="number" tickCount={6} tick={customTickY} />
        <Tooltip />
        <Legend
          verticalAlign="top"
          height={56}
          wrapperStyle={{
            marginLeft: "15px",
            paddingTop: "10px",
            paddingBottom: "15px",
            fontSize: "12px",
          }}
        />
        <CartesianGrid strokeDasharray="1 1" />
        <Bar
          dataKey="musculoskeletal score"
          fill="#8884d8"
          background={{ fill: "#eee" }}
        />
      </BarChart>
    </>
  );
};

export default Chartbar;
