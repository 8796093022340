import { styled } from "@mui/material/styles";
import DashboardNavbar from "../components/Navbar";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  // border: '1px solid red',
  // display: 'flex',
  // flex: '1 1 auto',
  maxWidth: "100%",
  height: "100vh",
  paddingTop: 64,
  //   [theme.breakpoints.up("lg")]: {
  //     paddingLeft: 240,
  //   },
  // height: 'calc(100% - 64px)'
}));

export const Layout = (props) => {
  const { handleAppClose, appTitle, children } = props;
  return (
    <>
      <DashboardLayoutRoot>{children}</DashboardLayoutRoot>
      <DashboardNavbar pageTitle={appTitle} handleAppClose={handleAppClose} />
    </>
  );
};

export default Layout;
