import React from "react";
import Chartbar from "../../components/ChartBar";
import { Box, Typography } from "@mui/material";

const SurveyEval = ({ data }) => {
  const neckAttrs = [
    "How difficult is it for you to sit in front of a computer for more than 2 hours",
    "How difficult is it for you to look over your shoulder",
    "How difficult is it for you to touch your chin on your chest",
    "How difficult is it for you to look up at the ceiling",
  ];

  const shoulderAttrs = [
    "How difficult is it for you to scratch your back",
    "How difficult is it for you to hold the steering wheel",
    "How difficult is it for you to put a sweater on and off",
    "How difficult is it for you to clean yourself after going to the bathroom",
    "How difficult is it for you to do overhead type activities",
    "How difficult is it for you to reach across your body",
    "How difficult is it for you to shampoo your hair",
  ];

  const handWristAttrs = [
    "How difficult is it for you to open medicine bottles or jars",
    "How difficult is it for you to type and write",
    "How difficult is it for you to make a tight fist",
    "How difficult is it for you to use buttons, snaps, hooks, or zippers",
    "How difficult is it for you to cut your own fingernails",
  ];

  const hipAttrs = [
    "How difficult is it for you to walk more than a mile",
    "How difficult is it for you to drive more than an hour",
    "How difficult is it for you to sit for more than 2 hours",
    "How difficult is it for you to put your socks and shoes on",
    "How difficult is it for you to get in or out of the bathtub or shower",
    "How difficult is it for you to sleep on your side",
    "How difficult is it for you to do heavy housework or yardwork, such as washing floors, vacuuming, or mowing lawns",
    "How difficult is it for you to touch your toes",
  ];

  const kneeAttrs = [
    "How difficult is it for you to jog or run",
    "How difficult is it for you to pivot",
    "How difficult is it for you to kneel or squat",
    "How difficult is it for you to go up and down stairs",
    "How difficult is it for you to get in or out of a low chair",
    "How often do you walk with a limp",
    "How often does your leg lock or give way",
  ];

  const findAvgSc = (data, attrs) => {
    let atCount = 0;
    let scTotal = 0;
    for (let at of attrs) {
      if (at in data) {
        atCount += 1;
        scTotal += parseInt(data[at]);
      }
    }
    if (atCount === 0) return 0;
    return scTotal / atCount;
  };

  const scores = {
    neck: findAvgSc(data, neckAttrs),
    shoulder: findAvgSc(data, shoulderAttrs),
    "hand/wrist": findAvgSc(data, handWristAttrs),
    hip: findAvgSc(data, hipAttrs),
    knee: findAvgSc(data, kneeAttrs),
  };

  const key = Object.keys(scores).reduce((a, b) =>
    scores[a] > scores[b] ? a : b
  );

  // construct the recommendation text
  const threshold = 3;
  function filterItems(arr) {
    return Object.keys(arr).filter((el) => arr[el] > threshold);
  }
  const keysOverThreshold = filterItems(scores);
  let recommendation = "";
  if (keysOverThreshold.length > 0) {
    const last = keysOverThreshold.pop();
    const result =
      keysOverThreshold.length > 0
        ? keysOverThreshold.join(", ") + " and " + last
        : last;
    recommendation =
      "We recommend that you do the " +
      result +
      " exercises from the MSK Handbook. Please contact your supervisor in case you dont have access to the Handbook.";
  } else {
    recommendation =
      "You are in good shape! we dont have any specific exercise for you. Please take this evaluation again in a months time or if you feel any physical discomfort.";
  }

  const scores_t = Object.keys(scores).map((key) => {
    return { name: key, "musculoskeletal score": scores[key] };
  });

  return (
    <>
      <Typography variant="h5">{recommendation}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Chartbar data={scores_t} />
      </Box>
    </>
  );
};

export default SurveyEval;
