import React from "react";
import { useRef, useState, useEffect } from "react";
import { useData } from "../../contexts/DataContext";
import SurveyEval from "./survey_eval_msk";
import surveyModel from "./survey_model_msk.js";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import LinearProgress from "@mui/material/LinearProgress";
import "survey-core/defaultV2.css";
import "./Survey.css";

import { Box, Typography } from "@mui/material";

Survey.StylesManager.applyTheme("defaultV2");

export function SurveyContainerMSK({ handleClose }) {
  const survey = useRef();
  const { handleSaveEvalData, openAlert } = useData();
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  function onComplete(result) {
    // console.log("Complete! ", result.data);
    setIsComplete((prev) => true);
    setIsDataFetching(true);
    handleSaveEvalData(result.data.employeeId, "MSK", result.data).then(
      (res) => {
        setIsDataFetching(false);
        openAlert({
          title: "Evaluation Result:",
          body: <SurveyEval data={result.data} />,
        });
        handleClose();
      }
    );
  }

  var model = new Survey.Model(surveyModel);
  return (
    <>
      {isDataFetching && <LinearProgress color="secondary" />}
      <div className="container">
        {!isComplete ? (
          <SurveyReact.Survey
            ref={survey}
            model={model}
            onComplete={onComplete}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            <Typography variant="h5" color="secondary">
              Analysing your data...
            </Typography>
          </Box>
        )}
      </div>
    </>
  );
}

export default SurveyContainerMSK;
