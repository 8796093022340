import React from "react";
import DataProvider from "./contexts/DataContext";
import Layout from "./containers/Layout";
import Home from "./pages/home";
import MSK from "./pages/msk";
import IframeContainer from "./pages/edu";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./App.css";
import Chat from "./pages/chat";

function App({ version }) {
  console.log("app load");

  const [selectedAppId, setSeletectAppId] = React.useState(null);
  const handleOpenApp = (id) => {
    console.log(`opening app -> ${id}`);
    setSeletectAppId(id);
  };
  const handleAppClose = () => {
    setSeletectAppId(null);
  };

  const apps = [
    {
      appId: 0,
      appTitle: "Musculoskeletal Assessment",
      appCmp: <MSK handleClose={handleAppClose} />,
      appName: "MSK Assessment",
      appImagePath: "/static/icon-msk.png",
    },
    {
      appId: 1,
      appTitle: "MSK Education",
      appCmp: (
        <IframeContainer
          handleClose={handleAppClose}
          iframe={
            '<iframe src="https://flairzhealth.com/strengthening-category/" title="Flairz MSK Education" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style="width:100%; height:100%; background-color:#fff; padding-top:0px; border:none; border-radius: 0px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
          }
        />
      ),
      appName: "MSK Education",
      appImagePath: "/static/icon-edu.png",
    },
    {
      appId: 2,
      appTitle: "Live Support",
      appCmp: (
        <IframeContainer
          handleClose={handleAppClose}
          iframe={
            '<iframe src="https://agent.flairzhealth.com/chat.html" title="Live Support" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style="width:100%; height:100%; background-color:#fff; padding-top:0px; border:none; border-radius: 0px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
          }
        />
      ),
      appName: "Live Support",
      appImagePath: "/static/icon-chat.png",
    },
  ];

  const getAppWithId = (id) => {
    return apps[selectedAppId];
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DataProvider>
          {selectedAppId != null ? (
            <Layout
              appTitle={getAppWithId(selectedAppId).appTitle}
              handleAppClose={handleAppClose}
            >
              {getAppWithId(selectedAppId).appCmp}
            </Layout>
          ) : (
            <Layout appTitle={"Home"}>
              <Home version={version} apps={apps} handleOpen={handleOpenApp} />
            </Layout>
          )}
        </DataProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
