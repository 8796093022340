import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

export const Logo = styled((props) => {
  const { variant, ...other } = props;

  const color = variant === "light" ? "#ffffff" : "#004374";

  return (
    <svg
      width="429"
      height="36"
      viewBox="20 12 400 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <rect x="235" y="24" width="36" height="38" fill="#D85559" />
      <rect x="277" y="24" width="36" height="38" fill="#BF606A" />
      <rect x="319" y="24" width="36" height="38" fill="#9E6E81" />
      <rect x="361" y="24" width="36" height="38" fill="#727D99" />
      <path
        d="M259.375 46.7656H262.375C262.219 48.2031 261.807 49.4896 261.141 50.625C260.474 51.7604 259.531 52.6615 258.312 53.3281C257.094 53.9844 255.573 54.3125 253.75 54.3125C252.417 54.3125 251.203 54.0625 250.109 53.5625C249.026 53.0625 248.094 52.3542 247.312 51.4375C246.531 50.5104 245.927 49.401 245.5 48.1094C245.083 46.8073 244.875 45.3594 244.875 43.7656V41.5C244.875 39.9062 245.083 38.4635 245.5 37.1719C245.927 35.8698 246.536 34.7552 247.328 33.8281C248.13 32.901 249.094 32.1875 250.219 31.6875C251.344 31.1875 252.609 30.9375 254.016 30.9375C255.734 30.9375 257.188 31.2604 258.375 31.9062C259.562 32.5521 260.484 33.4479 261.141 34.5938C261.807 35.7292 262.219 37.0469 262.375 38.5469H259.375C259.229 37.4844 258.958 36.5729 258.562 35.8125C258.167 35.0417 257.604 34.4479 256.875 34.0312C256.146 33.6146 255.193 33.4062 254.016 33.4062C253.005 33.4062 252.115 33.599 251.344 33.9844C250.583 34.3698 249.943 34.9167 249.422 35.625C248.911 36.3333 248.526 37.1823 248.266 38.1719C248.005 39.1615 247.875 40.2604 247.875 41.4688V43.7656C247.875 44.8802 247.99 45.9271 248.219 46.9062C248.458 47.8854 248.818 48.7448 249.297 49.4844C249.776 50.224 250.385 50.8073 251.125 51.2344C251.865 51.651 252.74 51.8594 253.75 51.8594C255.031 51.8594 256.052 51.6562 256.812 51.25C257.573 50.8438 258.146 50.2604 258.531 49.5C258.927 48.7396 259.208 47.8281 259.375 46.7656Z"
        fill="white"
      />
      <path
        d="M296.047 31.25V54H293.078V31.25H296.047ZM303.359 31.25V33.7188H285.781V31.25H303.359Z"
        fill="white"
      />
      <path
        d="M329.641 31.25H337.172C338.88 31.25 340.323 31.5104 341.5 32.0312C342.688 32.5521 343.589 33.3229 344.203 34.3438C344.828 35.3542 345.141 36.599 345.141 38.0781C345.141 39.1198 344.927 40.0729 344.5 40.9375C344.083 41.7917 343.479 42.5208 342.688 43.125C341.906 43.7188 340.969 44.1615 339.875 44.4531L339.031 44.7812H331.953L331.922 42.3281H337.266C338.349 42.3281 339.25 42.1406 339.969 41.7656C340.688 41.3802 341.229 40.8646 341.594 40.2188C341.958 39.5729 342.141 38.8594 342.141 38.0781C342.141 37.2031 341.969 36.4375 341.625 35.7812C341.281 35.125 340.74 34.6198 340 34.2656C339.271 33.901 338.328 33.7188 337.172 33.7188H332.656V54H329.641V31.25ZM342.938 54L337.406 43.6875L340.547 43.6719L346.156 53.8125V54H342.938Z"
        fill="white"
      />
      <path
        d="M387.438 51.5469V54H376.062V51.5469H387.438ZM376.656 31.25V54H373.641V31.25H376.656Z"
        fill="white"
      />
      <path
        d="M46.6733 24.3203L40.126 62H32.5435L39.0649 24.3203H46.6733ZM58.4482 40.417L57.3872 46.4727H40.7212L41.7822 40.417H58.4482ZM62.9771 24.3203L61.9419 30.4019H43.5161L44.5771 24.3203H62.9771ZM86.7663 55.9443L85.7053 62H67.2536L68.3146 55.9443H86.7663ZM76.2853 24.3203L69.738 62H62.1555L68.6769 24.3203H76.2853ZM112.186 31.1523L97.0209 62H88.6879L108.822 24.3203H114.153L112.186 31.1523ZM115.188 62L110.581 30.3501L110.866 24.3203H115.861L122.977 62H115.188ZM117.103 47.9736L116.042 54.0552H96.6586L97.7196 47.9736H117.103ZM142.497 24.3203L135.975 62H128.393L134.94 24.3203H142.497ZM150.888 24.3203L164.164 24.3462C166.458 24.3807 168.537 24.8034 170.401 25.6143C172.281 26.4251 173.739 27.6414 174.774 29.2632C175.827 30.8849 176.267 32.9466 176.094 35.4482C175.921 37.5013 175.421 39.2352 174.593 40.6499C173.782 42.0646 172.687 43.2637 171.306 44.2471C169.943 45.2305 168.373 46.0758 166.596 46.7832L163.957 48.0513H151.69L152.725 41.9956L161.162 42.0474C162.421 42.0301 163.569 41.7886 164.604 41.3228C165.639 40.8569 166.493 40.1841 167.166 39.3042C167.856 38.4071 168.287 37.3201 168.46 36.0435C168.615 34.922 168.537 33.9473 168.227 33.1191C167.934 32.291 167.425 31.644 166.7 31.1782C165.975 30.7124 165.052 30.4622 163.931 30.4277L157.435 30.4019L151.949 62H144.366L150.888 24.3203ZM164.992 62L159.557 45.2046L167.192 45.1528L173.066 61.5859V62H164.992ZM206.275 55.9443L205.24 62H179.284L180.345 55.9443H206.275ZM210.571 28.9785L182.881 62H177.317L178.171 57.2124L205.991 24.3203H211.399L210.571 28.9785ZM208.811 24.3203L207.75 30.4019H182.803L183.89 24.3203H208.811Z"
        fill={color}
      />
    </svg>
  );
})``;

Logo.defaultProps = {
  variant: "primary",
};

Logo.propTypes = {
  variant: PropTypes.oneOf(["light", "primary"]),
};

export default Logo;
