import React, { useState } from "react";
import Logo from "../components/Logo";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Paper,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
// import MenuItem from "@mui/material/MenuItem";
// import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
// import Loadingdata from "./Loaders";
import Avvvatars from "avvvatars-react";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[6],
}));

export default function DashboardNavbar(props) {
  const { pageTitle, handleAppClose, ...other } = props;

  //   const { isLocationLoading, selectedLocation } = useLocation();
  //   const { data: session, status } = useSession();

  const session = { fullName: "flairz-extern" };
  const selectedLocation = {
    orgLocationName: "Komatsu",
    address: { city: "USA" },
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleProfileClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 0,
        },
        width: {
          lg: "calc(100% - 0px)",
        },
      }}
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 30,
          left: 0,
          px: 2,
          backgroundColor: "#004374",
        }}
      >
        {/* <IconButton
          onClick={() => true}
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton> */}
        <Logo variant="light" sx={{ width: 200 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            // border: "1px red solid",
          }}
        >
          <Typography color="neutral.100" variant="h5" sx={{ fontWeight: 300 }}>
            {pageTitle}
          </Typography>
          {handleAppClose && (
            <IconButton color="neutral" onClick={() => handleAppClose()}>
              <CloseIcon fontSize="large" />
            </IconButton>
          )}
        </Box>
        <Stack direction="row" spacing={1}>
          <Chip
            avatar={
              <Avvvatars
                value={selectedLocation.address.city}
                size={32}
                style={"character"}
              />
            }
            variant="contained"
            color="secondary"
            label={selectedLocation.orgLocationName}
            sx={{ ml: 1, cursor: "pointer" }}
            //   onClick={() => router.push("/")}
          />
        </Stack>
        {/* <Tooltip title={`Logged in as ${session.fullName}`}>
          <IconButton onClick={handleProfileClick}>
            <Avvvatars value={session.fullName} size={36} style={"character"} />
          </IconButton>
        </Tooltip> */}
        <Paper>
          <Menu
            id="profile-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleProfileClose}
            MenuListProps={{
              "aria-labelledby": "profile-button",
            }}
          >
            {/* {status === "authenticated" && (
              <MenuItem
                // onClick={() =>
                //   signOut({
                //     callbackUrl: `${window.location.origin}/auth/login`,
                //   })
                // }
              >
                Sign Out
              </MenuItem>
            )} */}
          </Menu>
        </Paper>
      </Toolbar>
    </DashboardNavbarRoot>
  );
}

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};
