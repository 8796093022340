const surveyModel = {
  pages: [
    {
      name: "page_1",
      elements: [
        {
          type: "panel",
          name: "panel1",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/intro.png",
              contentMode: "image",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "first_page_container_panel",
              elements: [
                {
                  type: "text",
                  name: "employeeId",
                  title: "Employee ID",
                  isRequired: true,
                },
                {
                  type: "panel",
                  name: "employeeName",
                  elements: [
                    {
                      type: "text",
                      name: "employeeFirstName",
                      startWithNewLine: false,
                      title: "(First)",
                      minWidth: "100",
                      maxWidth: "200",
                      // isRequired: true,
                    },
                    {
                      type: "text",
                      name: "employeeLastName",
                      startWithNewLine: false,
                      title: "(Last)",
                      minWidth: "100",
                      maxWidth: "200",
                      // isRequired: true,
                    },
                    {
                      type: "text",
                      name: "employeeMiddleName",
                      startWithNewLine: false,
                      title: "(M.I)",
                      minWidth: "100",
                      maxWidth: "200",
                    },
                  ],
                  questionTitleLocation: "bottom",
                  title: "Employee Name",
                },
                {
                  type: "panel",
                  name: "panel5",
                  elements: [
                    {
                      type: "text",
                      name: "heightFt",
                      width: "200",
                      minWidth: "100",
                      maxWidth: "200",
                      title: "(Feet)",
                      titleLocation: "bottom",
                      inputType: "number",
                      min: 4,
                      max: 12,
                    },
                    {
                      type: "text",
                      name: "heightIn",
                      width: "200",
                      minWidth: "100",
                      maxWidth: "200",
                      startWithNewLine: false,
                      title: "(Inches)",
                      titleLocation: "bottom",
                      inputType: "number",
                      min: 0,
                      max: 12,
                    },
                  ],
                  title: "Your Height",
                },
                {
                  type: "panel",
                  name: "panel15",
                  elements: [
                    {
                      type: "text",
                      name: "Weight",
                      width: "200",
                      minWidth: "100",
                      maxWidth: "200",
                      title: "(Pounds)",
                      titleLocation: "bottom",
                      inputType: "number",
                      min: 40,
                      max: 400,
                    },
                  ],
                  title: "Your Weight",
                  startWithNewLine: false,
                },
              ],
              startWithNewLine: false,
            },
          ],
        },
      ],
      navigationTitle: "Employee info",
    },
    {
      name: "Left Body",
      elements: [
        {
          type: "panel",
          name: "panel1",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "100",
              maxWidth: "300",
              imageLink: "/static/body/left.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "page3_container_panel",
              elements: [
                {
                  type: "checkbox",
                  name: "Right side limits",
                  width: 200,
                  indent: 2,
                  title:
                    "For your LEFT SIDE please indicate those areas that bother you or limit your function.",
                  choices: [
                    "Neck",
                    "Shoulder",
                    "Elbow/Forearm",
                    "Wrist/Hand",
                    "Hip",
                    "Thigh",
                    "Knee ",
                    "Calf area",
                    "Ankle/Foot ",
                    "Back",
                    "None",
                  ],
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
    {
      name: "Right Body",
      elements: [
        {
          type: "panel",
          name: "panel7",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/right.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel8",
              elements: [
                {
                  type: "checkbox",
                  name: "Left side limits",
                  indent: 2,
                  title:
                    "For your RIGHT SIDE please indicate those areas that bother you or limit your function.",
                  choices: [
                    "Neck",
                    "Shoulder",
                    "Elbow/Forearm",
                    "Wrist/Hand",
                    "Hip",
                    "Thigh",
                    "Knee ",
                    "Calf area",
                    "Ankle/Foot ",
                    "Back",
                    "None",
                  ],
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
    {
      name: "Neck",
      elements: [
        {
          type: "panel",
          name: "panel9",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/neck-1.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel10",
              elements: [
                {
                  type: "html",
                  name: "question2",
                  indent: 2,
                  html: '<h3><b><font color="green">Neck</font></b></h3>\n<b> Please choose your difficult level </b>\n<br/>\n1 - Not at all difficult <br/>\n2 - A little difficult <br/>\n3 - Moderately difficult <br/>\n4 - Very difficult <br/>\n5 - Unable to do',
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to sit in front of a computer for more than 2 hours",
                  indent: 2,
                  title:
                    "How difficult is it for you to sit in front of a computer for more than 2 hours?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to look over your shoulder",
                  indent: 2,
                  title:
                    "How difficult is it for you to look over your shoulder?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to touch your chin on your chest",
                  indent: 2,
                  title:
                    "How difficult is it for you to touch your chin on your chest?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to look up at the ceiling",
                  indent: 2,
                  title:
                    "How difficult is it for you to look up at the ceiling?",
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
    {
      name: "Shoulder",
      elements: [
        {
          type: "panel",
          name: "panel2",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/shoulder-1.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel11",
              elements: [
                {
                  type: "html",
                  name: "question3",
                  indent: 2,
                  html: '<h3><b><font color="green">Shoulder</font></b></h3>\n<br/>\n<b> Please choose your difficult level </b>\n<br/>\n1 - Not at all difficult <br/>\n2 - A little difficult <br/>\n3 - Moderately difficult <br/>\n4 - Very difficult <br/>\n5 - Unable to do',
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to scratch your back",
                  indent: 2,
                  title: "How difficult is it for you to scratch your back?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to hold the steering wheel",
                  indent: 2,
                  title:
                    "How difficult is it for you to hold the steering wheel?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to put a sweater on and off",
                  indent: 2,
                  title:
                    "How difficult is it for you to put a sweater on and off ?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to clean yourself after going to the bathroom",
                  indent: 2,
                  title:
                    "How difficult is it for you to clean yourself after going to the bathroom?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to do overhead type activities",
                  indent: 2,
                  title:
                    "How difficult is it for you to do overhead type activities (changing lightbulbs, putting things on shelves, etc.)?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to reach across your body",
                  indent: 2,
                  title:
                    "How difficult is it for you to reach across your body?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to shampoo your hair",
                  indent: 2,
                  title: "How difficult is it for you to shampoo your hair?",
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
              indent: 2,
            },
          ],
        },
      ],
    },
    {
      name: "Elbow/Wrist",
      elements: [
        {
          type: "panel",
          name: "panel3",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/elbowwrist-1.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel12",
              elements: [
                {
                  type: "html",
                  name: "question5",
                  indent: 2,
                  html: '<h3><b><font color="green">Elbow/Wrist</font></b></h3>\n<br/>\n<b> Please choose your difficult level </b>\n<br/>\n1 - Not at all difficult <br/>\n2 - A little difficult <br/>\n3 - Moderately difficult <br/>\n4 - Very difficult <br/>\n5 - Unable to do',
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to open medicine bottles or jars",
                  indent: 2,
                  title:
                    "How difficult is it for you to open medicine bottles or jars?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to type and write",
                  indent: 2,
                  title: "How difficult is it for you to type and write?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to make a tight fist",
                  indent: 2,
                  title: "How difficult is it for you to make a tight fist?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to use buttons, snaps, hooks, or zippers",
                  indent: 2,
                  title:
                    "How difficult is it for you to use buttons, snaps, hooks, or zippers?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to cut your own fingernails",
                  indent: 2,
                  title:
                    "How difficult is it for you to cut your own fingernails?",
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
    {
      name: "Lower Back/Hip",
      elements: [
        {
          type: "panel",
          name: "panel4",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/lower-back-1.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel13",
              elements: [
                {
                  type: "html",
                  name: "question4",
                  indent: 2,
                  html: '<h3><b><font color="green">Lower Back/Hip</font></b></h3>\n<br/>\n<b> Please choose your difficult level </b>\n<br/>\n1 - Not at all difficult <br/>\n2 - A little difficult <br/>\n3 - Moderately difficult <br/>\n4 - Very difficult <br/>\n5 - Unable to do',
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to walk more than a mile",
                  indent: 2,
                  title:
                    "How difficult is it for you to walk more than a mile?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to drive more than an hour",
                  indent: 2,
                  title:
                    "How difficult is it for you to drive more than an hour?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to sit for more than 2 hours",
                  indent: 2,
                  title:
                    "How difficult is it for you to sit for more than 2 hours?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to put your socks and shoes on",
                  indent: 2,
                  title:
                    "How difficult is it for you to put your socks and shoes on ?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to get in or out of the bathtub or shower",
                  indent: 2,
                  title:
                    "How difficult is it for you to get in or out of the bathtub or shower?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to sleep on your side",
                  indent: 2,
                  title: "How difficult is it for you to sleep on your side?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to do heavy housework or yardwork, such as washing floors, vacuuming, or mowing lawns",
                  indent: 2,
                  title:
                    "How difficult is it for you to do heavy housework or yardwork, such as washing floors, vacuuming, or mowing lawns?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to touch your toes",
                  indent: 2,
                  title: "How difficult is it for you to touch your toes?",
                },
              ],
              questionTitleLocation: "top",
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
    {
      name: "Knee",
      elements: [
        {
          type: "panel",
          name: "panel14",
          elements: [
            {
              type: "image",
              name: "first_page_image",
              width: "300",
              minWidth: "300",
              maxWidth: "300",
              imageLink: "/static/body/knee-1.png",
              imageFit: "cover",
              imageHeight: 500,
              imageWidth: 300,
            },
            {
              type: "panel",
              name: "panel6",
              elements: [
                {
                  type: "html",
                  name: "question6",
                  indent: 2,
                  html: '<h3><b><font color="green">Knee</font></b></h3>\n<br/>\n<b> Please choose your difficult level </b>\n<br/>\n1 - Not at all difficult <br/>\n2 - A little difficult <br/>\n3 - Moderately difficult <br/>\n4 - Very difficult <br/>\n5 - Unable to do',
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to jog or run",
                  indent: 2,
                  title: "How difficult is it for you to jog or run?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to pivot",
                  indent: 2,
                  title: "How difficult is it for you to pivot?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to kneel or squat",
                  indent: 2,
                  title: "How difficult is it for you to kneel or squat?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to go up and down stairs",
                  indent: 2,
                  title:
                    "How difficult is it for you to go up and down stairs?",
                },
                {
                  type: "rating",
                  name: "How difficult is it for you to get in or out of a low chair",
                  indent: 2,
                  title:
                    "How difficult is it for you to get in or out of a low chair?",
                },
                {
                  type: "radiogroup",
                  name: "How often do you walk with a limp",
                  indent: 2,
                  title: "How often do you walk with a limp?",
                  choices: [
                    {
                      value: "1",
                      text: "None of the time",
                    },
                    {
                      value: "2",
                      text: "A little of the time",
                    },
                    {
                      value: "3",
                      text: "Some of the time",
                    },
                    {
                      value: "4",
                      text: "Most of the time",
                    },
                    {
                      value: "5",
                      text: "All of the time",
                    },
                  ],
                },
                {
                  type: "radiogroup",
                  name: "How often does your leg lock or give way",
                  indent: 2,
                  title: "How often does your leg lock or give way?",
                  choices: [
                    {
                      value: "1",
                      text: "None of the time",
                    },
                    {
                      value: "2",
                      text: "A little of the time",
                    },
                    {
                      value: "3",
                      text: "Some of the time",
                    },
                    {
                      value: "4",
                      text: "Most of the time",
                    },
                    {
                      value: "5",
                      text: "All of the time",
                    },
                  ],
                },
              ],
              startWithNewLine: false,
            },
          ],
        },
      ],
    },
  ],
  showNavigationButtons: "top",
  showQuestionNumbers: "off",
  showProgressBar: "top",
  progressBarType: "buttons",
  // logoPosition: "right",
  // completedHtml:
  //   "<h3>Thanks for the taking the survey</h3> <h5>Here is an analysis:</h5><img src='https://www.researchgate.net/profile/Leonard_Joseph/publication/235650048/figure/fig1/AS:393519513522176@1470833771055/Prevalence-of-work-related-musculoskeletal-disorders-based-on-SNMQ-data.png'/>",
  // completedHtmlOnCondition: [
  //   {
  //     expression: "{nps_score} > 8",
  //     html: "<h3>Thank you for your feedback.</h3> <h5>We glad that you love our product. Your ideas and suggestions will help us to make our product even better!</h5>",
  //   },
  //   {
  //     expression: "{nps_score} < 7",
  //     html: "<h3>Thank you for your feedback.</h3> <h5> We are glad that you share with us your ideas.We highly value all suggestions from our customers. We do our best to improve the product and reach your expectation.</h5><br/>",
  //   },
  // ],
};

export default surveyModel;
