import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({ open, handleClose, alertMsg }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600 }}>
        {alertMsg?.title}
        {/* <Button
          variant="outlined"
          size="large"
          onClick={() => console.log("print")}
          sx={{ ml: 2 }}
        >
          Print Result
        </Button> */}
        {/* <Button
          variant="outlined"
          size="large"
          onClick={() => console.log("print")}
          sx={{ ml: 2 }}
        >
          Get QR Code
        </Button> */}
      </DialogTitle>
      <DialogContent dividers sx={{ minWidth: 400, mx: 2 }}>
        {alertMsg?.body}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          onClick={handleClose}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
