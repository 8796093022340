import React from "react";
import { Box } from "@mui/material";

const IframeContainer = ({ iframe, handleClose }) => {
  function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        style={{ height: "90vh" }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <Iframe iframe={iframe} allow="autoplay" />,
    </Box>
  );
};

export default IframeContainer;
